.persons {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.person {
	display: flex;
	flex-direction: column;
	//justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 1rem;
	margin: 1rem 1rem 2rem;
	max-width: 25%;

	a {
		color: #242424;
		text-decoration: none;
	}

	@media all and (max-width: $breakpoint-mobile) {
		max-width: 40%;
	}
}

.person-image {
	display: flex;
	width: 200px;
	height: 200px;
	background: #a3a3a3;
	border-radius: 50%;
	margin-bottom: 1rem;
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
	}

	@media all and (max-width: 768px) {
		width: 120px;
		height: 120px;
	}
}

.person-name {
	font-size: 1.375rem;
	color: #5c47bf;
	font-weight: 700;
	margin-bottom: .375em;

	@media all and (max-width: $breakpoint-mobile) {
		font-size: 1.125rem;
	}
}

.person-phone {
	font-weight: 700;
}

.person-job {
	font-weight: 700;
}
