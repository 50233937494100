h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 800;
	text-transform: uppercase;
}

h2 {
	font-size: 2.625rem;

	@media all and (max-width: 768px) {
		font-size: 2rem;
	}

	@media all and (max-width: 680px) {
		font-size: 1.875rem;
	}
}

.text-center {
	text-align: center;
}

.color-white {
	color: white;
}

ul {
	margin-top: .5em;
	margin-bottom: .875em;

	li {
		margin-bottom: .875em;
		font-size: 1rem;
	}
}
