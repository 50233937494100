#masthead {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	margin: 0;
	padding-top: 1rem;
	padding-left: 0;
}

.menu-link {
	display: inline-block;
	color: white;
	text-decoration: none;
	white-space: nowrap;
	font-weight: 700;
	text-transform: uppercase;
	padding: .5rem 1.5rem;

	&:hover {
		color: #a68ff5;
	}
}
