.section {
	padding: 5rem 0;

	@media all and (max-width: 768px) {
		padding: 3rem 0;
	}

	@media all and (max-width: 680px) {
		padding: 2rem 0;
	}

	&.section-bg {
		background: #f7f5fc;
	}

	&.section-mb {
		margin-bottom: 2rem;
	}
}

.section-content {
	margin: 3rem auto 0;
	max-width: 930px;
	font-size: 1.25rem;

	@media all and (max-width: 768px) {
		font-size: 1.125rem;
	}

	@media all and (max-width: 680px) {
		font-size: 1rem;
	}
}

.section-heading {
	text-align: center;
	font-size: 3rem;
	margin-bottom: .5em;
	color: #5c47bf;

	@media all and (max-width: $breakpoint-mobile) {
		font-size: 2rem;
	}
}

.section-h2 {
	font-size: 2rem;
}

.section-content-wrapper {
	background: white;
	border: 1px dashed #707070;
	padding: 1rem;
	margin-bottom: 2rem;

	iframe {
		width: 100%;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.table-program {
	width: 100%;
	margin-bottom: 1rem;

	th {
		text-align: center;
		font-weight: 700;
		font-size: 1.5rem;
		color: white;
		width: 200px;
		background: #5c47bf;
	}

	td {
		padding: 1.25rem 2rem;
		border: 1px dashed #707070;
		font-size: 1.25rem;
	}

	tr:nth-child(odd) th {
		background: #a68ff5;
	}

	tr.table-program-grey:nth-child(odd) th {
		background: #555;
	}

	.table-program-grey {

		th {
			background: #555;
		}
	}
}

.table-program-heading {
	font-weight: 700;
	color: #5c47bf;
	font-size: 1.25em;
}
