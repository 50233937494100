.logos {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 4rem;
}

.logo {
	margin: 2rem;

	img {
		width: auto;
		max-height: 70px;
	}

	@media all and (max-width: $breakpoint-mobile) {
		margin: 1.25rem;

		img {
			width: auto;
			max-height: 50px;
		}
	}
}
