$max-layout-width: 1290px;
$breakpoint-mobile: 680px;

html,
body {
	//background: #fafaf8;
	color: #242424;
	position: relative;
	font-family: Roboto, sans-serif;
}

.container {
	position: relative;
	width: 100%;
	max-width: $max-layout-width + 100px;
	margin: 0 auto;
	padding: 0 50px;

	@media all and (max-width: $breakpoint-mobile) {
		padding: 0 20px;
	}
}

.container-full {
	position: relative;
	width: 100%;
	padding: 0 50px;

	@media all and (max-width: $breakpoint-mobile) {
		padding: 0 20px;
	}
}

#footer-site {
	padding: 1.5rem 0;

	.credits {
		text-align: right;
		font-size: .675rem;
		margin: 0;

		a {
			font-weight: 700;
			color: #5c47bf;
			text-decoration: none;
		}
	}
}
