.intro {
	color: white;
}

.intro-section-top {
	background: #5c47bf;
	text-align: center;
	padding: 12rem 3rem 0;

	img {
		width: 100%;
		max-width: 579px;
		height: auto;
	}

	@media all and (max-width: $breakpoint-mobile) {
		padding: 12rem 1rem 0;
	}
}

.intro-section-bottom {
	background: #a68ff5;
	text-align: center;
	padding: 6rem 3rem;

	@media all and (max-width: $breakpoint-mobile) {
		padding: 3rem 1rem;
	}
}

.intro-type {
	font-size: 1.25rem;
	margin-bottom: 1em;

	@media all and (max-width: $breakpoint-mobile) {
		font-size: 1rem;
	}
}

.intro-title {
	font-size: 3rem;
	margin-bottom: .5em;

	@media all and (max-width: $breakpoint-mobile) {
		font-size: 2rem;
	}
}

.intro-date {
	font-size: 2rem;

	@media all and (max-width: $breakpoint-mobile) {
		font-size: 1.25rem;
	}
}

.intro-place {
	font-size: 1.25rem;

	@media all and (max-width: $breakpoint-mobile) {
		font-size: 1rem;
	}
}

.intro-text {
	font-size: 1.25rem;
	line-height: 1.75;
	background: #f7f5fc;

	@media all and (max-width: $breakpoint-mobile) {
		font-size: 1rem;
	}
}
